.box { 
    color: #fff;

    &--lightgrey {        
        background-image: linear-gradient(to bottom right, #fff, #EAEAEA);
        color: #000;
    }

    &--orange { 
        background: #EB5E25;
    }    

    &--red { 
        background: #95245A;
    }   

    &--orange & { 
        &__saving { 
            background: #912150;
        }
    }
    
    &--green { 
        background: #0E7970;
    }

    &--green & { 
        &__saving { 
            background: #3E938C;
        }
    }    

    h2 { 
        margin: 0;
        font-size: 2.500rem;
        font-weight: 500;
        white-space: pre-line;
    }

    h3 { 
        margin: 0;
        font-size: 1.0em;
        font-weight: 500;
    }

    &__no-saving { 
        display: grid;
        grid-template-columns: 1fr;
        padding: 20px;
    }

    &__has-saving { 
        display: grid;
        grid-template-columns: 1fr;
        padding: 0px 0px;
    }

    &__numbers { 
        font-size: 2.500rem;
        margin-top: 10px;
    }

    &__numberscenter { 
        text-align: center;
    }

    &__text { 
        text-align: center;
        font-size: 1.000rem;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    &__inner { 
        display: grid;
        grid-template-columns: 1fr; 
    }

    &__stats { 
        display: grid;
        margin-top: 10px;
        grid-template-columns: 1fr;
        align-items: center;

        div { 
            display: flex;
            flex-flow: column;
            font-size: 1.5em;
        }

        ul {
            margin-left: -40px;
        }
    }

    &__has-saving { 
        h2 { 
            padding: 10px 20px 0 20px;
        }
        h3 { 
            padding: 5px 10px 0 0;
        }
    }

    &__no-saving { 
        h2 { 
            padding: 10px 20px 0 20px;
        }
        h3 { 
            padding: 5px 20px 0 20px;
        }
    }
    
    &__has-saving & { 
        &__stats { 
            padding: 0px;
        }
    }

    &__no-saving & { 
        &__stats { 
            padding: 20px;
        }
    }

    &__saving { 
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
    }

    &__info {
        background-image: linear-gradient(to bottom right, #fff, #EAEAEA);
        color:#000;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }

    .infoText {
        font-size: 0.75em;
        padding: 10px;
        margin-bottom: 10px;
        text-align: center;
    }
    .roundedButton {
        background: #fff;
        color: #000;
        border: 2px solid #EB5E25;
        display: block;
        padding: 10px;
        text-align: center;
        text-decoration: none;
        border-radius: 8px; // the rounded corners are here        
    }
}

@media only screen and (max-width: 820px) {
    .box { 
        &__no-saving { 
            grid-template-columns: 1fr;
        }

        &__stats { 
            margin-top: 10px;
        }

        &__inner { 
            grid-template-columns: 1fr;
        }

        &__saving { 
            padding: 20px;
        }
    }
}

@media only screen and (max-width: 540px) {
    .box { 
        &__stats { 
            margin-top: 10px;
            grid-template-columns: 1fr;
            gap: 20px;
        }
    }
}
