.inputs { 
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
    margin-bottom: 0px;

    .msoffering {
        padding: 40px;
        background-image: linear-gradient(to bottom right, #EDEDED, #CECECE);
    }

    .cpsoffering {
        text-align: center;
        background-color: #000;
        color: #fff;
        gap: 0px;
        margin-bottom: 0px;
    }


    &__input {
        display: flex;
        flex-flow: column;
        gap: 10px;
        background-color: #EDEDED;
        align-items: center;
        justify-content: center;

        h2 { 
            margin: 0;
            font-size: 1.750rem;
            font-weight: 500;
            white-space: pre-line;
            margin-bottom: 10px;
        }

        .inputIcons {
            text-align: center;
            font-size: 4.500em;
            font-weight: 200;
            margin-bottom: 0px;
        }

        .infoIcons {
            text-align: center;
            vertical-align: middle;
            font-size: 0.75em;
        }
        
        input { 
            font-size: 1.25rem;
            border: solid 1px #9D9D9D;
            color: #EB5E25;
            font-weight: 400;
            text-align: center;
            height: 50px;
            border-radius: 10px;
            padding: 0 30px;
            width: 30%;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            &[type=number] {
                -moz-appearance: textfield;
            }            
        
            &:focus { 
                outline: solid 2px #9D9D9D;
            }
        }

        label { 
            font-size: 1.500rem;
            color: #003349;
            text-align: left;
        }
    }

    &__field {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;

        h2 { 
            margin: 0;
            font-size: 2.500rem;
            font-weight: 500;
            white-space: pre-line;
        }
            
        input { 
            font-size: 1.25rem;
            border: solid 1px #9D9D9D;
            height: 60px;
            border-radius: 10px;
            padding: 0 20px;
            width: 40%;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            &[type=number] {
                -moz-appearance: textfield;
            }            
        
            &:focus { 
                outline: solid 2px #9D9D9D;
            }
        }

        label { 
            font-size: 1.25rem;
            width: 75%;
            color: #003349;
            text-align: left;
        }
    }
}

@media only screen and (max-width: 700px) {
    .inputs { 
        &__input { 
            label { 
                font-size: 1.5rem;
            }
        }
    }
}

@media only screen and (max-width: 430px) {
    .inputs { 
        grid-template-columns: 1fr;
    }
}