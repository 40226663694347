.calcHeadline
{
    font-size: 1.750em;
    margin-bottom: 20px;
    font-weight: 400;
    
    .leftBar {
        border-left: 4px solid #F28664;
        margin-right: 5px;
        height:100px;
    }    
}
.mainArea
{
    border: solid 1px #C0C0C0;
    background-color: White;
    padding: 20px;
    box-shadow: 10px -10px 5px  rgba(136,136,136,0.6);
    -moz-box-shadow: 10px -10px 5px  rgba(136,136,136,0.6);
    -webkit-box-shadow: 10px -10px 5px  rgba(136,136,136,0.6);
    -o-box-shadow: 10px -10px 5px  rgba(136,136,136,0.6);
    border-radius:25px;

    .phonesHeadline {
        font-size: 1.750em;
        margin-bottom: 10px;    
        font-weight: 400;
    }

    .phonesStrapline {
        font-size: 1.00em;
        margin-bottom: 10px;    
    }
}

.boxes { 
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
    margin-bottom: 10px;

    &__condition { 
        text-align: center;
        margin: 0;
        font-size: 1rem;
        font-weight: 400;
        color: #003349;
    }
}
